import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Preview = makeShortcode("Preview");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "with-two-pages-comprised-entirely-of-carbon-components-lets-revisit-the-landing-page-and-build-a-couple-components-of-our-own-by-using-carbon-icons-and-tokens"
    }}>{`With two pages comprised entirely of Carbon components, let’s revisit the landing page and build a couple components of our own by using Carbon icons and tokens.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Review design</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Use components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add styling</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Check accessibility</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`Carbon provides a solid foundation for building web applications through its color palette, layout, spacing, type, as well as common building blocks in the form of components. So far, we’ve only used Carbon components to build out two pages.`}</p>
    <p>{`Next, we’re going to use Carbon assets to build application-specific components. We’ll do so by including accessibility and responsive considerations all throughout.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://vue-step-5--carbon-tutorial-vue.netlify.com"
      }}>{`preview`}</a>{` of what you’ll build (see bottom of page):`}</p>
    <Preview height="400" title="Carbon Tutorial Step 4" src="https://vue-step-5--carbon-tutorial-vue.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" mdxType="Preview" />
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-1#fork-clone--branch"
      }}>{`step 1 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b vue-step-4 upstream/vue-step-4
`}</code></pre>
    <p><em parentName="p">{`Note: This builds on top of step 3, but be sure to check out the upstream step 4 branch because it includes the static assets required to get through this step.`}</em></p>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies (in case you’re starting fresh in your current directory and not continuing from the previous step):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-3"
      }}>{`previous step`}</a>{` left off.`}</p>
    <h2 {...{
      "id": "review-design"
    }}>{`Review design`}</h2>
    <p>{`Here’s what we’re building – an informational section that has a heading and three subheadings. Each subheading has accompanying copy and a pictogram. We’ll assume that this informational section is used elsewhere on the site, meaning it’s a great opportunity to build it as a resusable component. As for naming, we’ll call it an `}<inlineCode parentName="p">{`InfoSection`}</inlineCode>{` with three `}<inlineCode parentName="p">{`InfoCard`}</inlineCode>{`s as children.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "36.24454148471616%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABRElEQVQoz1VR2XKDMAzk/78stM1MmoMALU04DCEnxjZpnrYrZTrTPmhsrVerlRXdncNkGtwPHR79AY/jUe9Ta3hK3uObuGChbRV/9MSIT8wD80lqyb2PIyJ3u6FPtxj2O7i6hqP4pSjQrFfo0hS2LIlXOH7kqFfEtgk8OQNxs1mjfF/AJBu4qoS/XhEFaxGaRkmuqRG6Fn2WIotnyN9eVSDwbc/CdEbsJWbzPQYKCCeLY5TLJTzNeJqLwjBQvYKnmAZHuO12OOU5zl+Fuhb8wCYmSRQfKWYZxXyOmpOci08KVn8EJRGXzXNkcTsZo25/cS85mwWeOg1DJpM/Vd4/QZlfnAjIggs7XulOChVjyL9a5T2nkfspzzSXr/LypoKjheO2PDflug6eGx0pKiF33wnewtKJI0dy4Y48rTSUOm5YNWjuBxvABosCsXIDAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Info section layout",
        "title": "Info section layout",
        "src": "/static/8cf9fc4b464f9f414da13476f19e600e/fb070/info-layout.png",
        "srcSet": ["/static/8cf9fc4b464f9f414da13476f19e600e/d6747/info-layout.png 288w", "/static/8cf9fc4b464f9f414da13476f19e600e/09548/info-layout.png 576w", "/static/8cf9fc4b464f9f414da13476f19e600e/fb070/info-layout.png 1152w", "/static/8cf9fc4b464f9f414da13476f19e600e/fb104/info-layout.png 1728w", "/static/8cf9fc4b464f9f414da13476f19e600e/902fb/info-layout.png 2304w", "/static/8cf9fc4b464f9f414da13476f19e600e/1d306/info-layout.png 2748w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Info section layout</Caption>
    <h2 {...{
      "id": "create-components"
    }}>{`Create components`}</h2>
    <p>{`First we need files for the components, so create an `}<inlineCode parentName="p">{`InfoSection`}</inlineCode>{` folder in `}<inlineCode parentName="p">{`src/components`}</inlineCode>{`. Even though we’re building multiple components, their names all start with `}<inlineCode parentName="p">{`Info`}</inlineCode>{`, so it makes sense to have them share one folder in components. Create these files:`}</p>
    <h3 {...{
      "id": "add-files"
    }}>{`Add files`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/components/InfoSection
├──index.js
└──InfoCard.vue
└──InfoSection.vue
`}</code></pre>
    <p>{`Like our other components, `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` will serve as an entrypoint.`}</p>
    <p><em parentName="p">{`Note: To adhere to the `}<a parentName="em" {...{
          "href": "https://vuejs.org/v2/style-guide/"
        }}>{`Vue style guide`}</a>{` we have used multi word component names. This style guide stipulation is why all `}<inlineCode parentName="em">{`@carbon/vue`}</inlineCode>{` components have a `}<inlineCode parentName="em">{`cv`}</inlineCode>{` prefix.`}</em></p>
    <h5 {...{
      "id": "srccomponentsinfosectionindexjs"
    }}>{`src/components/InfoSection/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import InfoSection from './InfoSection';
import InfoCard from './InfoCard';

export { InfoSection, InfoCard };
`}</code></pre>
    <h3 {...{
      "id": "infosection-component"
    }}>{`InfoSection component`}</h3>
    <p>{`Let’s create the parent component that includes the “The Principles” heading. That markup currently looks like this in `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="bx--row landing-page__r3">
  <div class="bx--col-md-4 bx--col-lg-4">
    <h3 class="landing-page__label">The Principles</h3>
  </div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Open</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Modular</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Consistent</div>
</div>
`}</code></pre>
    <p>{`We want to do a few things when abstracting it to a component. First, we only want Carbon (`}<inlineCode parentName="p">{`bx--`}</inlineCode>{`) and this component’s class names; we don’t want to include `}<inlineCode parentName="p">{`landing-page__r3`}</inlineCode>{` as that’s specific to the landing page. That will be passed in as a property to the component.`}</p>
    <p>{`We’ll also:`}</p>
    <ul>
      <li parentName="ul">{`Add component class names like `}<inlineCode parentName="li">{`info-section`}</inlineCode>{` and `}<inlineCode parentName="li">{`info-section__heading`}</inlineCode></li>
      <li parentName="ul">{`Semantically use `}<inlineCode parentName="li">{`<section>`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`<div>`}</inlineCode></li>
      <li parentName="ul">{`Update the grid columns to match the design`}</li>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`The Principles`}</inlineCode>{` with `}<inlineCode parentName="li">{`{{heading}}`}</inlineCode></li>
      <li parentName="ul">{`Replace columns 2 - 4 with a slot.`}</li>
    </ul>
    <p>{`Using `}<inlineCode parentName="p">{`props`}</inlineCode>{` we can render any heading and any number of children components (`}<inlineCode parentName="p">{`InfoCard`}</inlineCode>{` that we’ll build soon.)`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfosectionvue"
    }}>{`src/components/InfoSection/InfoSection.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <section class="bx--row info-section">
    <div class="bx--col-md-8 bx--col-lg-4 bx--col-xlg-3">
      <h3 class="info-section__heading">{{ heading }}</h3>
    </div>
    <slot />
  </section>
</template>
`}</code></pre>
    <p>{`Then name our component and add a property to the script section.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfosectionvue-1"
    }}>{`src/components/InfoSection/InfoSection.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
export default {
  name: "InfoSection",
  props: {
    heading: String
  }
};
</script>
`}</code></pre>
    <p>{`At this point let’s add styling for the new class names that we just added.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfosectionvue-2"
    }}>{`src/components/InfoSection/InfoSection.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`<style lang="scss">
@import "../../styles/_carbon-utils";

.info-section__heading {
  @include carbon--type-style('heading-01');
}
</style>
`}</code></pre>
    <h3 {...{
      "id": "infocard-component"
    }}>{`InfoCard component`}</h3>
    <p>{`Next up we’re going to build a component for columns 2 - 4, which currently looks like `}<inlineCode parentName="p">{`<div class="bx--col-md-4 bx--col-lg-4">Carbon is Open</div>`}</inlineCode>{`. Create a new file InfoCard.vue, add:`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <article
    class="info-card bx--col-md-4 bx--col-lg-4 bx--col-xlg-3 bx--offset-xlg-1"
  >
    <h4 class="info-card__heading">{{ heading }}</h4>
    <p class="info-card__body">{{ body }}</p>
    <component :is="icon" />
  </article>
</template>
`}</code></pre>
    <p>{`Give it a name and add props`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
export default {
  name: "InfoCard",
  props: {
    heading: String,
    body: String,
    icon: Object
  }
};
</script>
`}</code></pre>
    <p><em parentName="p">{`Note: Make sure to export the two components from index.js!`}</em></p>
    <p>{`In doing so, we:`}</p>
    <ul>
      <li parentName="ul">{`Used the semantic `}<inlineCode parentName="li">{`<article>`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`<div>`}</inlineCode></li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`info-card`}</inlineCode>{` classes`}</li>
      <li parentName="ul">{`Used `}<inlineCode parentName="li">{`props`}</inlineCode>{` to render the heading, body copy, and icon`}</li>
      <li parentName="ul">{`Set columns to match the grid`}</li>
    </ul>
    <p><em parentName="p">{`Note: At extra large viewports, we are using `}</em><inlineCode parentName="p">{`bx--col-xlg-3 bx--offset-xlg-1`}</inlineCode><em parentName="p">{` so each column takes up 3 of the 16 grid columns, with a 1 column offset.`}</em></p>
    <h2 {...{
      "id": "use-components"
    }}>{`Use components`}</h2>
    <p>{`Our styling is not complete yet, but with our components built let’s put them to use. In `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`, import the components towards the top of the script section. If you haven’t added a script section, do so now.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-1"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
import { InfoSection, InfoCard } from '../../components/InfoSection';

export default {
  name: 'LandingPage',
  components: { InfoSection, InfoCard }
};
</script>
`}</code></pre>
    <p>{`While we’re here next to the component imports, let’s import the icons that we’ll need as well.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-2"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Globe32 from '@carbon/icons-vue/lib/globe/32';
import PersonFavorite32 from '@carbon/icons-vue/lib/person--favorite/32';
import Application32 from '@carbon/icons-vue/lib/application/32';
`}</code></pre>
    <p><em parentName="p">{`Note: You’ll notice that these 32px icons aren’t the pictograms as designed. The Carbon team is currently working on adding pictograms to the icons packages. Until then, we’ll use the biggest SVGs.`}</em></p>
    <p>{`Don’t forget to add the icons to the list of components used in our template. Wait a minute, are they being used by our template? Well, yes and no. The components are going to be passed as an attribute rather than being used as a DOM element. Vue treats this use case differently and instead of adding to the components property, we need to assign the icons directly to the `}<inlineCode parentName="p">{`this`}</inlineCode>{` object. We can achieve this in a number of different ways, for example as data or computed propeties. The following is our preferred method when reactivity is not needed.`}</p>
    <p>{`In the script section of the component add the lifecycle method `}<inlineCode parentName="p">{`created()`}</inlineCode>{` to add the icons to the component.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-3"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  created() {
    // Add icons to this
    Object.assign(this, {
      Globe32,
      PersonFavorite32,
      Application32
    });
  }
`}</code></pre>
    <p>{`With everything imported, replace the current template content:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-4"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="bx--row landing-page__r3">
  <div class="bx--col-md-4 bx--col-lg-4">
    <h3 class="landing-page__label">The Principles</h3>
  </div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Open</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Modular</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Consistent</div>
</div>
`}</code></pre>
    <p>{`With the new components:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-5"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<info-section heading="The Principles" class="landing-page__r3">
</info-section>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Then slot the `}<inlineCode parentName="p">{`InfoCard`}</inlineCode>{` content inside the `}<inlineCode parentName="p">{`InfoSection`}</inlineCode>{` tag to give.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-6"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<info-section heading="The Principles" class="landing-page__r3">
  <info-card
    heading="Carbon is Open"
    body="It's a distributed effort, guided by the principles of the open-source movement. Carbon's users are also it's makers, and everyone is encouraged to contribute."
    :icon="PersonFavorite32"
  />
  <info-card
    heading="Carbon is Modular"
    body="Carbon's modularity ensures maximum flexibility in execution. It's components are designed to work seamlessly with each other, in whichever combination suits the needs of the user."
    :icon="Application32"
  />
  <info-card
    heading="Carbon is Consistent"
    body="Based on the comprehensive IBM Design Language, every element and component of Carbon was designed from the ground up to work elegantly together to ensure consistent, cohesive user experiences."
    :icon="Globe32"
  />
</info-section>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p><em parentName="p">{`Note: Now is a good time to resize your browser from phone to extra large viewport widths to see how the responsive grid is working before we add further styling.`}</em></p>
    <h2 {...{
      "id": "add-styling"
    }}>{`Add styling`}</h2>
    <p>{`Here’s our design showing the spacing tokens that we need to add. We also need to set the type style and borders.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "36.24454148471616%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABMUlEQVQoz12RT1ODMBDF+eR+Iu+eHQ+Oh844HYu0tpQ/BQRKgASSYHWeu6kwHQ8Pkh/J28eud04t3h4spouG0RbfMAhfJsSvBl8/I6yZYIyB1hrDMGAciVnrGK+Z8beZeaJS2K5KtJ1A30uMVsJ/EvBXCYrPE9q2Jd6jKApEUYQsy6CUgiCeJAnCMHRvKaXjntYjzDS4DUOumGc5gq2PIAgWg8PhgM1m45gQwhXa+T7eSeHx6M7wXY8fnEwquVQRrUBZlqjremF5niNNU8f7rkNDhs/bHfZxjLqqljDOcL40p7xWu/bnNrnbkwyxE7E7atNaDbiMFOq/4Sw24GRN0yxmLGYdJbuaK1TnDo/7AiX92fB3xhnypG4TsngIrNvUs5mUCpoGl38orO954sqxuYe/C4kRXhAvaZgAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Info section spacing",
        "title": "Info section spacing",
        "src": "/static/b70a1121747b250385e039a8075abf38/fb070/info-spacing.png",
        "srcSet": ["/static/b70a1121747b250385e039a8075abf38/d6747/info-spacing.png 288w", "/static/b70a1121747b250385e039a8075abf38/09548/info-spacing.png 576w", "/static/b70a1121747b250385e039a8075abf38/fb070/info-spacing.png 1152w", "/static/b70a1121747b250385e039a8075abf38/fb104/info-spacing.png 1728w", "/static/b70a1121747b250385e039a8075abf38/902fb/info-spacing.png 2304w", "/static/b70a1121747b250385e039a8075abf38/1d306/info-spacing.png 2748w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Info section spacing</Caption>
    <h3 {...{
      "id": "layout"
    }}>{`Layout`}</h3>
    <p>{`Starting with layout, add the style section to `}<inlineCode parentName="p">{`src/components/InfoSection/InfoCard.vue`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-1"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`<style lang="scss">
@import "../../styles/carbon-utils";

.info-card {
  margin-top: $spacing-09;
  display: flex;
  flex-direction: column;

  svg {
    margin-top: $spacing-09;
  }

  // top border in only small breakpoints to prevent overrides
  @include carbon--breakpoint-down(md) {
    &:not(:nth-child(2)) {
      border-top: 1px solid $ui-03;
      padding-top: $spacing-09;
    }
  }

  // left border in just the 2nd column items
  @include carbon--breakpoint(md) {
    &:nth-child(odd) {
      border-left: 1px solid $ui-03;
    }
  }

  // left border in all items
  @include carbon--breakpoint(lg) {
    margin-top: 0;
    border-left: 1px solid $ui-03;

    svg {
      margin-top: $layout-06;
    }
  }
}
</style>
`}</code></pre>
    <p>{`Once you save, go ahead and resize your browser to see the responsive layout at the different breakpoints. Make sure to review these color and spacing tokens. There are also a few breakpoint mixins that may be new to you. The `}<inlineCode parentName="p">{`@carbon/layout`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/layout/docs/sass.md"
      }}>{`SassDoc`}</a>{` is a great reference to see what all is available.`}</p>
    <h3 {...{
      "id": "type"
    }}>{`Type`}</h3>
    <p>{`Our `}<inlineCode parentName="p">{`InfoCard`}</inlineCode>{` headings look to be too small. We need to increase their font sizes according to the design spec with:`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-2"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.info-card__heading {
  @include carbon--type-style('productive-heading-03');
}
`}</code></pre>
    <p>{`Also, the design has the last word in each subheading as bold. To accomplish that, add this computed property to `}<inlineCode parentName="p">{`InfoCard.vue`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-3"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`computed: {
  // Take in a phrase and separate the third word in an array
  splitHeading() {
    const splitHeading = this.heading.split(" ");
    const finalWord = splitHeading.pop();
    return [splitHeading.join(" "), finalWord];
  }
}
`}</code></pre>
    <p>{`Then, update `}<inlineCode parentName="p">{`InfoCard.vue`}</inlineCode>{` to use `}<inlineCode parentName="p">{`splitHeading`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-4"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <p>{`Replacing`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h4 class="info-card__heading">
  {{heading}}
</h4>
`}</code></pre>
    <p>{`with`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-5"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h4 class="info-card__heading">
  {{ splitHeading[0] }}
  <strong>{{ splitHeading[1] }}</strong>
</h4>
`}</code></pre>
    <p>{`Finally, add the following declaration block in the style section of `}<inlineCode parentName="p">{`InfoCard.vue`}</inlineCode>{` to set body copy styles and to bottom-align the icons.`}</p>
    <h5 {...{
      "id": "srccomponentsinfosectioninfocardvue-6"
    }}>{`src/components/InfoSection/InfoCard.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.info-card__body {
  margin-top: $spacing-06;
  flex-grow: 1; // fill space so icons are bottom aligned
  @include type-style('body-long-01');

  // prevent large line lengths between small and medium viewports
  @include carbon--breakpoint-between(321px, md) {
    max-width: 75%;
  }
}
`}</code></pre>
    <h2 {...{
      "id": "check-accessibility"
    }}>{`Check accessibility`}</h2>
    <p>{`We’ve added new markup and styles, so it’s a good practice to check `}<a parentName="p" {...{
        "href": "https://www.ibm.com/able/dynamic-assessment-plug-in.html"
      }}>{`DAP`}</a>{` and make sure our rendered markup is on the right track for accessibility.`}</p>
    <p>{`With the browser extension installed, Chrome in this example, open Dev Tools and run DAP.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.79797979797979%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5ElEQVQoz6WR63KiQBCFef932qy3bG2yGpMIBhRkRQUEEWEAL4hfZqi4/1PbVV19+jKnek5reZ6TH1KyNKM8lFR5RXEo2qhykQrKrPxXa6PMjxKfihNZlnE4HEjTlCRJ0C51zXQR4oQu/XmXR+eRzscDA7tP1+rSM7s8u0/0rJ7sDeiYP+lYfX6YA0brIalIOR6PCCFacq2WhKaXMI9dOu4Dw+0T42zCa/rGeDfiIzWwMhs9nTCS+Th54T0Z8rYf8xK/cGqO3K1pGrTqXDP8NWL40EMfT/D8En/whDkcs/FCNpuYYKnjSxwsN7IW8WwKfo/mhDKPtjGmaeI4Dr7vo51vMP8zZdrpY8xMgijGtUwMfUIQhqyCkLX3zsJ1sR2bv8sltufzbnzIvs9+v2exWLS+3W7R1Kq1KIhmi3Y4kwIXlTyIdIVzqYsQFep4qlapXpFzuZwRRdEeRGlYlmXb05rbDaVjsdvj2Lbcwmm/YBhGGy3LYjaz0HWdpeexk5fc7XYkcrPkC6stoyhqL60pIetrQxpEeN6qHbwPKN9/5erxHSuSllTWlKst78SaqI7UcYBwZ6z8gPiL5G43+YPvuNSwYRVmvE49NmuP9XrN+Xxum2r7bxM21yuXBk5XSSCxIvkf+wR7DTrhLPYW9AAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "DAP violations",
        "title": "DAP violations",
        "src": "/static/d53d52c5afae6e60aa0fdf0a9b12816e/fb070/DAP-violations.png",
        "srcSet": ["/static/d53d52c5afae6e60aa0fdf0a9b12816e/d6747/DAP-violations.png 288w", "/static/d53d52c5afae6e60aa0fdf0a9b12816e/09548/DAP-violations.png 576w", "/static/d53d52c5afae6e60aa0fdf0a9b12816e/fb070/DAP-violations.png 1152w", "/static/d53d52c5afae6e60aa0fdf0a9b12816e/e0c45/DAP-violations.png 1584w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">DAP violations</Caption>
    <p>{`That first violation is for the off-screen “skip to content” link. This link isn’t shown and is used to assist screen reading, so the color contrast violation can be ignored.`}</p>
    <p>{`But, those three other violations came from the `}<inlineCode parentName="p">{`<article>`}</inlineCode>{` element used in new `}<inlineCode parentName="p">{`InfoCard`}</inlineCode>{`. Since the `}<inlineCode parentName="p">{`<article>`}</inlineCode>{` element requires a label, it seems like we may be using the wrong semantic element. A humble `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` will suffice.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`InfoCard.vue`}</inlineCode>{`, replace the `}<inlineCode parentName="p">{`<article>`}</inlineCode>{` opening and closing tags with `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` tags.`}</p>
    <p>{`Also it’s time to fix it if you didn’t but I’m sure you can manage that by yourself.`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#continuous-integration-ci-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 4"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin vue-step-4
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`vue-step-4`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: vue-step-4`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      